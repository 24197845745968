import { Box, BoxProps, Text } from '@chakra-ui/react'
import React from 'react'

type CreateVoucherStepTitleProps = {
  campaignName?: string
} & BoxProps

const CreateVoucherStepTitle: React.FC<CreateVoucherStepTitleProps> = ({
  children,
  campaignName,
  ...boxProps
}) => {
  return (
    <Box textStyle="h3" display="inline-flex" {...boxProps}>
      {!!campaignName && (
        <>
          <Text as="span" color="primary.500">
            {campaignName}
          </Text>
          <Box
            height="1.75rem"
            borderStyle="solid"
            borderColor="primary.300"
            borderLeftWidth="1px"
            marginX="0.75rem"
          />
        </>
      )}
      <Text as="span" color="primary.400">
        {children}
      </Text>
    </Box>
  )
}

export default CreateVoucherStepTitle
