import _ from 'lodash'
import React from 'react'

import { Flex, HStack, Text } from '@chakra-ui/react'
import { useCampaignContext } from 'routes/Campaigns/context/CampaignContext'
import VoucherSearch from '../VoucherSearch'
import { DownloadReportButton } from './components/DownloadReportButton'
import { CreateVoucherButton } from './components/CreateVoucherButton'

type VoucherHeaderProps = {
  vouchersTotalCount: number
  onClickCreateNew: (() => void) | null
  onClickBulkCreate: (() => void) | null
  onClickOpenDownloadTransactionModal: (() => void) | null
  onClickDownloadVouchers: (() => void) | null
  onClickDownloadCampaignVoucherLinks: (() => void) | null
  canDownloadVouchers: boolean
  canDownloadTransactions: boolean
  canCreateVouchers: boolean
  canBulkCreateVouchers: boolean
  canDownloadCampaignVoucherLinks: boolean
  isDownloadCampaignVoucherLinksLoading: boolean
}

const VoucherHeader: React.FC<VoucherHeaderProps> = ({
  vouchersTotalCount,
  onClickCreateNew,
  onClickBulkCreate,
  onClickOpenDownloadTransactionModal,
  onClickDownloadVouchers,
  onClickDownloadCampaignVoucherLinks,
  canDownloadVouchers,
  canDownloadTransactions,
  canCreateVouchers,
  canBulkCreateVouchers,
  canDownloadCampaignVoucherLinks,
  isDownloadCampaignVoucherLinksLoading,
}) => {
  const { isBulkCreateCampaign, campaignDefaultVouchers } = useCampaignContext()
  const isCreateVoucherButtonDisabled = _.isNull(campaignDefaultVouchers)

  return (
    <>
      <Flex alignItems="center">
        <Text textStyle="h2" marginLeft="32px" color="neutral.800">
          Vouchers
        </Text>
        {!!vouchersTotalCount && (
          <Text textStyle="subhead1" marginLeft="36px" color="neutral.900">
            {vouchersTotalCount} total recipients
          </Text>
        )}
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginX="32px"
        marginY="24px"
      >
        <VoucherSearch />
        <HStack spacing="8px">
          {(canCreateVouchers || canBulkCreateVouchers) && (
            <CreateVoucherButton
              isBulkCreateCampaign={isBulkCreateCampaign}
              onClickCreateNew={onClickCreateNew}
              onClickBulkCreate={onClickBulkCreate}
              isDisabled={isCreateVoucherButtonDisabled}
            />
          )}
          {(canDownloadVouchers ||
            canDownloadTransactions ||
            canDownloadCampaignVoucherLinks) && (
            <DownloadReportButton
              isBulkCreateCampaign={isBulkCreateCampaign}
              canDownloadVouchers={canDownloadVouchers}
              canDownloadTransactions={canDownloadTransactions}
              canDownloadCampaignVoucherLinks={canDownloadCampaignVoucherLinks}
              onClickOpenDownloadTransactionModal={
                onClickOpenDownloadTransactionModal
              }
              onClickDownloadVouchers={onClickDownloadVouchers}
              onClickDownloadCampaignVoucherLinks={
                onClickDownloadCampaignVoucherLinks
              }
              isDownloadCampaignVoucherLinksLoading={
                isDownloadCampaignVoucherLinksLoading
              }
            />
          )}
        </HStack>
      </Flex>
    </>
  )
}

export default React.memo(VoucherHeader)
