/* eslint-disable react/jsx-props-no-spreading */
// react-table utilizes prop spreading

import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Box, Flex, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import NextPrevPagination from 'components/NextPrevPagination'
import VoucherRow from './components/VoucherRow'
import VoucherTableEmptyState from './components/VoucherTableEmptyState'
import VoucherTableLoadingRows from './components/VoucherTableLoadingRows'
import VoucherTablePlainText from './components/VoucherTablePlainText'

function VoucherTable({
  vouchers,
  config,
  isLoading,
  onClickNextPage,
  onClickPreviousPage,
  isLoadingNextPage,
  isLoadingPreviousPage,
  currentSearchQuery,
}) {
  const currentSearchQueryLength = currentSearchQuery.length
  // At length 0, there is a query fired but not for length 1 and 2 but we take all as having empty query string
  const hasSufficientCharsForSearchQuery = !_.inRange(
    currentSearchQueryLength,
    0,
    3
  )
  const isEmptyVoucherReceiptForCampaign =
    currentSearchQueryLength === 0 &&
    vouchers &&
    !vouchers.length &&
    !hasSufficientCharsForSearchQuery
  const isInvalidSearchParams =
    _.inRange(currentSearchQueryLength, 1, 3) &&
    !hasSufficientCharsForSearchQuery
  const isEmptySearchResult =
    currentSearchQueryLength > 2 &&
    vouchers &&
    !vouchers.length &&
    hasSufficientCharsForSearchQuery
  return (
    <>
      <Flex flexDirection="column" minHeight="912px">
        <Table>
          <Thead>
            <Tr>
              {_.map(config, (col, i) => (
                // Disallow last column from expanding
                <Th
                  key={col.key}
                  // TODO fix this optional-chaining
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  width={i === config?.length - 1 ? col.width : 'auto'}
                >
                  <Box width={col.width}>{col.display}</Box>
                </Th>
              ))}
              <Th key="actions" width="4rem">
                <Box width="4rem" />
              </Th>
            </Tr>
          </Thead>
          <Tbody id="vouchers-table-body">
            {!isEmptyVoucherReceiptForCampaign &&
              !isInvalidSearchParams &&
              !isEmptySearchResult &&
              _.map(vouchers, (object) => (
                <VoucherRow key={object.id} object={object} config={config} />
              ))}
            {/* Loading state */}
            {isLoading && !vouchers && <VoucherTableLoadingRows />}
          </Tbody>
        </Table>
        {isEmptyVoucherReceiptForCampaign && (
          <VoucherTablePlainText message="No voucher recipient has been added to this campaign" />
        )}
        {isInvalidSearchParams && (
          <VoucherTablePlainText message="Please enter at least 3 characters" />
        )}
        {isEmptySearchResult && <VoucherTableEmptyState />}
      </Flex>
      {!!vouchers?.length && (
        <NextPrevPagination
          onClickNext={onClickNextPage}
          onClickPrevious={onClickPreviousPage}
          isLoadingNextPage={isLoadingNextPage}
          isLoadingPreviousPage={isLoadingPreviousPage}
        />
      )}
    </>
  )
}

VoucherTable.propTypes = {
  vouchers: PropTypes.arrayOf(PropTypes.object),
  config: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClickNextPage: PropTypes.func,
  onClickPreviousPage: PropTypes.func,
  isLoadingNextPage: PropTypes.bool,
  isLoadingPreviousPage: PropTypes.bool,
  isLoading: PropTypes.bool,
  currentSearchQuery: PropTypes.string.isRequired,
}

export default React.memo(VoucherTable)
