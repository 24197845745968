import axios, { AxiosRequestTransformer, AxiosResponseTransformer } from 'axios'
import humps from 'humps'
import qs from 'qs'

import { loadUser } from 'services/Storage'

const { REACT_APP_REDEEM_URL: REDEEM_URL } = process.env

// Casting of transformers is a known issue for Axios: https://github.com/axios/axios/issues/1910

// Config to snake_case outgoing post body and query strings, and camelCase incoming
// responses
export const baseConfiguration = {
  // Configure base url
  baseURL: `${REDEEM_URL}/v1`,
  // Inject device headers into all outgoing requests.
  // headers: someHeaders,
  // Add our own transformation after the default setting transform
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
    (data: object[]) => humps.camelizeKeys(data),
  ],
  // Add our transformation before passing to default setting to transform
  transformRequest: [
    (data: object[]) => humps.decamelizeKeys(data),
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
  paramsSerializer: (params: object[]) => {
    return qs.stringify(humps.decamelizeKeys(params))
  },
}

// Helper to load user and read the token and craft the Auth Header
export function getAuthorizationHeader() {
  const user = loadUser()
  const accessToken = user.token
  return { Authorization: `Bearer ${accessToken}` }
}

// Create the api service which operational needs calls
const ApiService = axios.create(baseConfiguration)

export default ApiService
