/* eslint-disable react/jsx-props-no-spreading */
// react-table utilizes prop spreading

import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
  BiDotsHorizontalRounded,
  BiEditAlt,
  // BiEditAlt,
  BiPrinter,
  BiSend,
  BiShow,
} from 'react-icons/bi'

import {
  Box,
  Center,
  Tr,
  Td,
  Text,
  Icon,
  Flex,
  Tooltip,
} from '@chakra-ui/react'
import IconButton from 'components/IconButton'

function VoucherRow({
  group,
  config,
  isPrintLoading,
  onPrintClick,
  onSendClick,
  isSending,
  onSelectGroup,
  onEditClick,
  canPrint,
  canUpdate,
  canSend,
}) {
  return (
    <Tr
      _hover={{
        background: 'primary.100',
      }}
      role="group"
    >
      {_.map(config, (col) => (
        <Td
          key={col.key}
          _groupHover={{
            display: col.hideOnHover ? 'none' : 'table-cell',
          }}
        >
          <Box width={col.width}>
            <Text as="div" textStyle="subhead2" color="neutral.900">
              {col.headerFn(group)}
            </Text>
            <Text as="div" textStyle="body2" color="neutral.700">
              {col.bodyFn(group)}
            </Text>
          </Box>
        </Td>
      ))}
      <Td
        display="none"
        padding="16px 32px"
        _groupHover={{
          display: 'table-cell',
        }}
        colSpan={2}
      >
        {/* Hack to ensure it doesn't cause table cell size to change */}
        <Flex width="1px">
          {canPrint && (
            <Tooltip hasArrow label="Print voucher" placement="top">
              <IconButton
                key="print"
                variant="outline"
                colorScheme="primary"
                onClick={onPrintClick}
                isLoading={isPrintLoading}
                icon={BiPrinter}
              />
            </Tooltip>
          )}
          {canSend && (
            <Tooltip hasArrow label="Send vouchers by SMS" placement="top">
              <IconButton
                key="send"
                variant="outline"
                colorScheme="primary"
                marginLeft="8px"
                onClick={() => {
                  onSendClick()
                }}
                isDisabled={!group.contactNumber}
                isLoading={isSending}
                icon={BiSend}
              />
            </Tooltip>
          )}

          {canUpdate && (
            <Tooltip hasArrow label="Edit details" placement="top">
              <IconButton
                key="edit"
                variant="outline"
                marginLeft="8px"
                colorScheme="primary"
                onClick={onEditClick}
                icon={BiEditAlt}
              />
            </Tooltip>
          )}
          <Tooltip hasArrow label="View more" placement="top">
            <IconButton
              key="more-info"
              variant="outline"
              colorScheme="primary"
              marginLeft="8px"
              icon={BiShow}
              data-dd-action-name="View voucher details button"
              onClick={() => onSelectGroup(group)}
            />
          </Tooltip>
        </Flex>
      </Td>
      <Td
        _groupHover={{
          display: 'none',
        }}
      >
        <Center padding="8px">
          <Icon as={BiDotsHorizontalRounded} width="1.5rem" height="1.5rem" />
        </Center>
      </Td>
    </Tr>
  )
}

VoucherRow.propTypes = {
  group: PropTypes.objectOf(PropTypes.any).isRequired,
  onSendClick: PropTypes.func.isRequired,
  isSending: PropTypes.bool,
  config: PropTypes.arrayOf(PropTypes.object).isRequired,
  isPrintLoading: PropTypes.bool,
  onPrintClick: PropTypes.func.isRequired,
  onSelectGroup: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  canSend: PropTypes.bool,
  canPrint: PropTypes.bool,
  canUpdate: PropTypes.bool,
}

export default React.memo(VoucherRow)
