import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  useCreateCampaignVoucher,
  useCampaignVouchersPaginated,
  useCampaignVouchersSearchExists,
} from 'hooks/CampaignVouchers'
import {
  useSendGroupedVouchers,
  useUpdateGroupedVoucherDetails,
  useGetVouchersToPrint,
} from 'hooks/Voucher'
import _ from 'lodash'
import { printVouchers } from 'hooks/Print'
import { useToast } from 'data/Toasts'
import { TYPE as ToastType } from 'components/Toast'
import { functionStateWrapper, scrollToTop } from 'helpers/utils'
import { useDebouncedCallback } from 'use-debounce'
import { usePrevious } from '@chakra-ui/react'
import {
  useCampaignVoucherReportURL,
  useDownloadCampaignVoucherLinks,
} from 'hooks/CampaignReports'
import { useAuthenticationState } from 'data/Authentication'
import { UserActions } from 'constants/permissions'
import { useGroupedVoucherEvents } from 'hooks/Events'
import { useStopwatch } from 'hooks/stopwatch'
import {
  logCreateVouchersAction,
  useLogCreateVoucherStepChanges,
} from 'helpers/monitoring'
import { useCheckIfNricIsNotInWhitelistForCampaign } from 'hooks/Whitelist'
import { useCampaignContext } from 'routes/Campaigns/context/CampaignContext'
import { VOUCHER_TYPES } from './components/CreateVoucherModal/constants'

export const VouchersTabContext = createContext()

// TOOD: Refactor this, it's too huge And convert in typescript in the future
// eslint-disable-next-line react/prop-types
export const VouchersTabProvider = ({ children }) => {
  const {
    hasWhitelist,
    campaignId,
    campaignName,
    campaignValidityEnd: campaignExpiresAt,
    totalGroupedVouchersCount,
    extraQrPrefix,
  } = useCampaignContext()

  const { permissions } = useAuthenticationState()
  const { toast, toastError } = useToast()
  const [isPrintLoading, setIsPrintLoading] = useState(false)
  const [isCreateLoading, setIsCreateLoading] = useState(false)
  const [currentCreateStep, setCurrentCreateStep] = useState(0)
  const [isCreateVoucherModalOpen, setIsCreateVoucherModalOpen] = useState(
    false
  )
  const [
    isBulkCreateVoucherModalOpen,
    setIsBulkCreateVoucherModalOpen,
  ] = useState(false)
  const [bulkCreateModalKey, setBulkCreateModalKey] = useState(0)
  const [
    isDownloadTransactionsModalOpen,
    setIsDownloadTransactionsModalOpen,
  ] = useState(false)
  const [filterVouchersValue, setFilterVouchersValue] = useState('')
  const previousFilterVouchersValue = usePrevious(filterVouchersValue)
  const [
    selectedVoucherGroupIdToUpdate,
    setSelectedVoucherGroupIdToUpdate,
  ] = useState(null)
  const [
    selectedVoucherGroupIdToSend,
    setSelectedVoucherGroupIdToSend,
  ] = useState(null)
  const [
    isCreateVoucherAddressUsePrefill,
    setIsCreateVoucherAddressUsePrefill,
  ] = useState(true)
  const {
    resetAndStart: resetAndStartCreateVoucherStopwatch,
    getCurrentTimeMillis: getCreateVoucherStopwatchTimeMillis,
  } = useStopwatch()
  const {
    createCampaignVoucher,
    // createCampaignVoucherStatus,
    // createCampaignVoucherResponse,
    // createCampaignVoucherError,
    // resetCreateCampaignVoucher,
  } = useCreateCampaignVoucher(campaignId)
  useLogCreateVoucherStepChanges({ stepNumber: currentCreateStep })
  const {
    vouchers,
    isFetchingVouchersByCampaignId,
    isFetchingVouchersByCampaignIdNextPage,
    isFetchingVouchersByCampaignIdPreviousPage,
    getNextPageOfVouchersByCampaignId,
    getPreviousPageOfVouchersByCampaignId,
    refreshFetchVouchersByCampaignId,
    updateFetchVouchersByCampaignIdSearchQuery,
    fetchVouchersByCampaignCurrentSearchQuery,
    // fetchVouchersByCampaignIdError,
    // fetchVouchersByCampaignIdStatus,
  } = useCampaignVouchersPaginated({
    campaignId,
    enabled: ({ search }) => !_.inRange(search.length, 1, 3),
  })
  const hasPermissionToSendVouchers = permissions?.[
    campaignId
  ]?.actions?.includes(UserActions.SendGroupedVouchers)
  const hasPermissionToUpdateVouchers = permissions?.[
    campaignId
  ]?.actions?.includes(UserActions.UpdateGroupedVoucherContact)
  const hasPermissionToPrintVouchers = permissions?.[
    campaignId
  ]?.actions?.includes(UserActions.PrintGroupedVouchers) // TODO: Switch to print voucher permission once implemented
  const {
    downloadCampaignVoucherReport: onDownloadCampaignVoucherReport,
    isDownloadCampaignVoucherReportLoading,
  } = useCampaignVoucherReportURL(campaignId)
  const {
    downloadCampaignVoucherLinks: onDownloadCampaignVoucherLinks,
    isDownloadCampaignVoucherLinksLoading,
  } = useDownloadCampaignVoucherLinks(campaignId)
  const debouncedSearch = useCallback(
    useDebouncedCallback(updateFetchVouchersByCampaignIdSearchQuery, 500),
    [updateFetchVouchersByCampaignIdSearchQuery]
  )
  useEffect(() => {
    if (_.isUndefined(previousFilterVouchersValue)) {
      return
    }
    debouncedSearch(filterVouchersValue)
    // previousFilterVouchersValue should not be a dependency as it depends on filterVouchersValue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, filterVouchersValue])
  const {
    updateGroupedVoucherDetails,
    isUpdateGroupedVoucherDetailsLoading,
  } = useUpdateGroupedVoucherDetails({ campaignId })
  const getIsVoucherGroupsMatchingQueryExists = useCampaignVouchersSearchExists(
    campaignId
  )
  const checkIfNricIsNotInWhitelistForCampaign = useCheckIfNricIsNotInWhitelistForCampaign(
    { campaignId, hasWhitelist }
  )
  // const [vouchersToSend, setVouchersToSend] = useState(null)
  const [selectedVoucherType, setSelectedVoucherType] = useState(null)
  const [selectedGroupId, setSelectedGroupId] = useState(null)
  const resetCreateModal = useCallback(() => {
    setSelectedVoucherType(null)
    setCurrentCreateStep(0)
    setIsCreateVoucherAddressUsePrefill(true)
  }, [])
  const {
    sendGroupedVouchers,
    sendGroupedVouchersStatus,
    sendGroupedVouchersError,
    resetSendGroupedVouchers,
  } = useSendGroupedVouchers()
  const {
    groupedVoucherEvents,
    isFetchingGroupedVoucherEvents,
  } = useGroupedVoucherEvents({ groupId: selectedGroupId })
  const onGetVouchersToPrint = useGetVouchersToPrint()
  const onPrintUnusedVouchers = useCallback(
    (name, groupId) => {
      setIsPrintLoading(true)
      // Adds a delay so that spinner loads before
      // browser processes print.
      setTimeout(() => {
        onGetVouchersToPrint({ groupId }).then(
          ({ vouchers: vouchersToPrint }) => {
            printVouchers({
              vouchers: vouchersToPrint,
              onPrintDialogOpen: () => {
                setIsPrintLoading(false)
              },
              name,
              campaignName,
              campaignExpiresAt,
              extraQrPrefix,
            })
          }
        )
      }, 1000)
    },
    [onGetVouchersToPrint, campaignName, campaignExpiresAt]
  )

  const selectedGroup = useMemo(
    () => _.find(vouchers, (group) => group.id === selectedGroupId),
    [selectedGroupId, vouchers]
  )
  const selectedGroupToUpdate = useMemo(() => {
    const chosenGroup = _.find(
      vouchers,
      (group) => group.id === selectedVoucherGroupIdToUpdate
    )
    return chosenGroup
  }, [selectedVoucherGroupIdToUpdate, vouchers])
  const selectedGroupToSend = useMemo(() => {
    return _.find(
      vouchers,
      (group) => group.id === selectedVoucherGroupIdToSend
    )
  }, [selectedVoucherGroupIdToSend, vouchers])
  const onCreateVoucherModalApplySearch = useCallback(
    (value) => {
      setIsCreateVoucherModalOpen(false)
      resetCreateModal()
      setFilterVouchersValue(value)
    },
    [resetCreateModal, setFilterVouchersValue]
  )
  const onCloseGroup = useCallback(() => setSelectedGroupId(null), [])
  const onSelectGroup = useCallback((group) => setSelectedGroupId(group.id), [])
  const closeUpdateVoucherGroupModal = useCallback(
    () => setSelectedVoucherGroupIdToUpdate(null),
    []
  )
  const updateVoucherGroup = useCallback(
    functionStateWrapper(
      () =>
        toast({
          title: 'Save successful!',
          description: "Recipient's mobile number has been updated",
          status: ToastType.SUCCESS,
        }),
      toastError
    )((updateGroupParams) =>
      updateGroupedVoucherDetails({
        groupId: selectedVoucherGroupIdToUpdate,
        updateGroupParams,
      }).then(() => setSelectedVoucherGroupIdToUpdate(null))
    ),
    [functionStateWrapper, toast, selectedVoucherGroupIdToUpdate]
  )
  const closeCreateVoucherModal = useCallback(() => {
    if (isCreateLoading) {
      return
    }
    setIsCreateVoucherModalOpen(false)
    resetCreateModal()
  }, [resetCreateModal, isCreateLoading])
  const closeBulkCreateVoucherModal = useCallback(() => {
    setIsBulkCreateVoucherModalOpen(false)
  }, [setIsBulkCreateVoucherModalOpen])
  const onCreateCampaignVoucher = useCallback(
    useDebouncedCallback(
      async ({
        voucherLabelParam,
        voucherNameParam,
        voucherContactNumberParam,
        voucherNricParam,
        voucherBlockNumberParam,
        voucherStreetNameParam,
        voucherPostalCodeParam,
        voucherFloorNumberParam,
        voucherUnitNumberParam,
        voucherGroupDenominationsParam,
      }) => {
        try {
          const result = await createCampaignVoucher({
            ...(voucherLabelParam && { label: voucherLabelParam }),
            ...(voucherNameParam && { name: voucherNameParam }),
            ...(voucherContactNumberParam && {
              contactNumber: voucherContactNumberParam,
            }),
            ...(voucherNricParam && { nric: voucherNricParam }),
            ...(voucherGroupDenominationsParam && {
              values: voucherGroupDenominationsParam,
            }),
            ...(voucherBlockNumberParam && {
              block: voucherBlockNumberParam,
            }),
            ...(voucherStreetNameParam && {
              street: voucherStreetNameParam,
            }),
            ...(voucherPostalCodeParam && {
              postalCode: voucherPostalCodeParam,
            }),
            ...(voucherFloorNumberParam && {
              floor: voucherFloorNumberParam,
            }),
            ...(voucherUnitNumberParam && {
              unit: voucherUnitNumberParam,
            }),
          })

          if (selectedVoucherType === VOUCHER_TYPES.DIGITAL) {
            await sendGroupedVouchers(result.id)
            logCreateVouchersAction({
              type: selectedVoucherType,
              timeTaken: getCreateVoucherStopwatchTimeMillis(),
              isUsePrefill: isCreateVoucherAddressUsePrefill,
            })
          } else if (selectedVoucherType === VOUCHER_TYPES.PAPER) {
            const { vouchers: vouchersToPrint } = await onGetVouchersToPrint({
              groupId: result.id,
            })
            await printVouchers({
              vouchers: vouchersToPrint,
              name: result.name,
              campaignName,
              campaignExpiresAt,
              onPrintDialogOpen: () => {
                logCreateVouchersAction({
                  type: selectedVoucherType,
                  timeTaken: getCreateVoucherStopwatchTimeMillis(),
                  isUsePrefill: isCreateVoucherAddressUsePrefill,
                })
              },
              extraQrPrefix,
            })
          }
        } catch (error) {
          toastError(error)
        } finally {
          setIsCreateVoucherModalOpen(false)
          setIsCreateLoading(false)
          resetCreateModal()
          refreshFetchVouchersByCampaignId()
        }
      },
      1000
    ),
    [
      createCampaignVoucher,
      refreshFetchVouchersByCampaignId,
      resetCreateModal,
      selectedVoucherType,
      sendGroupedVouchers,
      onGetVouchersToPrint,
      toastError,
      getCreateVoucherStopwatchTimeMillis,
    ]
  )
  const onSetLoadingAndCreateCampaignVoucher = useCallback(
    async ({
      voucherLabelParam,
      voucherNameParam,
      voucherContactNumberParam,
      voucherNricParam,
      voucherBlockNumberParam,
      voucherStreetNameParam,
      voucherPostalCodeParam,
      voucherFloorNumberParam,
      voucherUnitNumberParam,
      voucherGroupDenominationsParam,
    }) => {
      setIsCreateLoading(true)
      return onCreateCampaignVoucher({
        voucherLabelParam,
        voucherNameParam,
        voucherContactNumberParam,
        voucherNricParam,
        voucherBlockNumberParam,
        voucherStreetNameParam,
        voucherPostalCodeParam,
        voucherFloorNumberParam,
        voucherUnitNumberParam,
        voucherGroupDenominationsParam,
      })
    },
    [onCreateCampaignVoucher]
  )
  const closeSendGroupedVoucherModal = useCallback(() => {
    setSelectedVoucherGroupIdToSend(null)
    resetSendGroupedVouchers()
  }, [resetSendGroupedVouchers])
  const getNextPageOfVouchers = useMemo(() => {
    if (!getNextPageOfVouchersByCampaignId) {
      return null
    }
    return async () => {
      await getNextPageOfVouchersByCampaignId()
      scrollToTop()
    }
  }, [getNextPageOfVouchersByCampaignId])
  const getPreviousPageOfVouchers = useMemo(() => {
    if (!getPreviousPageOfVouchersByCampaignId) {
      return null
    }
    return () => {
      getPreviousPageOfVouchersByCampaignId()
      scrollToTop()
    }
  }, [getPreviousPageOfVouchersByCampaignId])
  const onCloseDownloadTransactionsModal = useCallback(
    () => setIsDownloadTransactionsModalOpen(false),
    []
  )
  const onOpenDownloadTransactionsModal = useCallback(() => {
    setIsDownloadTransactionsModalOpen(true)
  }, [setIsDownloadTransactionsModalOpen])
  const onClickCreateNew = useCallback(() => {
    resetAndStartCreateVoucherStopwatch()
    setIsCreateVoucherModalOpen(true)
  }, [])
  const onClickBulkCreate = useCallback(() => {
    setIsBulkCreateVoucherModalOpen(true)
    setBulkCreateModalKey((prev) => prev + 1)
  }, [])

  return (
    <VouchersTabContext.Provider
      value={{
        campaignId,
        campaignName,
        extraQrPrefix,
        campaignExpiresAt,
        getIsVoucherGroupsMatchingQueryExists,
        checkIfNricIsNotInWhitelistForCampaign,
        vouchersTotalCount: totalGroupedVouchersCount,
        currentSearchQuery: fetchVouchersByCampaignCurrentSearchQuery,
        getNextPageOfVouchers,
        getPreviousPageOfVouchers,
        isLoadingNextPage: isFetchingVouchersByCampaignIdNextPage,
        isLoadingPreviousPage: isFetchingVouchersByCampaignIdPreviousPage,
        onSelectGroup,
        onCloseGroup,
        selectedGroup,
        currentCreateStep,
        setCurrentCreateStep,
        isCreateVoucherAddressUsePrefill,
        setIsCreateVoucherAddressUsePrefill,
        onCreateVoucherModalApplySearch,
        isLoading: isFetchingVouchersByCampaignId,
        isPrintLoading,
        onPrintUnusedVouchers,
        setIsPrintLoading,
        vouchers,
        filterVouchersValue,
        setFilterVouchersValue,
        selectedVoucherType,
        setSelectedVoucherType,
        isCreateLoading,
        isUpdateVoucherGroupModalOpen: !!selectedGroupToUpdate,
        isDownloadTransactionsModalOpen,
        onCloseDownloadTransactionsModal,
        onOpenDownloadTransactionsModal,
        selectedGroupToUpdate,
        openUpdateVoucherGroupModal: setSelectedVoucherGroupIdToUpdate,
        closeUpdateVoucherGroupModal,
        updateVoucherGroup,
        isSendGroupedVoucherModalOpen: !!selectedGroupToSend,
        selectedGroupToSend,
        openSendGroupedVoucherModal: setSelectedVoucherGroupIdToSend,
        closeSendGroupedVoucherModal,
        sendGroupedVouchersStatus,
        sendGroupedVouchersError,
        onSendGroupedVouchers: sendGroupedVouchers,
        isUpdateVoucherGroupLoading: isUpdateGroupedVoucherDetailsLoading,
        // Create voucher modal controls
        onClickCreateNew,
        onClickBulkCreate,
        isCreateVoucherModalOpen,
        isBulkCreateVoucherModalOpen,
        closeCreateVoucherModal,
        closeBulkCreateVoucherModal,
        setIsCreateVoucherModalOpen,
        setIsBulkCreateVoucherModalOpen,
        createCampaignVoucher: onSetLoadingAndCreateCampaignVoucher,
        onDownloadCampaignVoucherReport,
        isDownloadCampaignVoucherReportLoading,
        onDownloadCampaignVoucherLinks,
        isDownloadCampaignVoucherLinksLoading,
        groupedVoucherEvents,
        isLoadingGroupedVoucherEvents: isFetchingGroupedVoucherEvents,
        hasPermissionToSendVouchers,
        hasPermissionToUpdateVouchers,
        hasPermissionToPrintVouchers,
        bulkCreateModalKey,
        setBulkCreateModalKey,
      }}
    >
      {children}
    </VouchersTabContext.Provider>
  )
}

export function useVouchersTabContext() {
  const context = useContext(VouchersTabContext)
  if (context === undefined) {
    throw new Error(
      'useVouchersTabContext must be used inside a VouchersTabProvider'
    )
  }
  return context
}
