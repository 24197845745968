import React from 'react'
import PropTypes from 'prop-types'
import { Button, Flex, Icon, Text } from '@chakra-ui/react'

export default function Tile({
  id,
  subtitle,
  title,
  icon,
  isSelected,
  onClick,
}) {
  return (
    <Button
      alignItems="flex-start"
      height="fit-content"
      color="primary.700"
      background={isSelected ? 'primary.100' : 'white'}
      borderWidth={isSelected ? '3px' : '1px'}
      borderStyle="solid"
      borderColor={isSelected ? 'primary.500' : 'neutral.300'}
      borderRadius="4px"
      id={id}
      onClick={onClick}
      paddingInlineEnd={0}
      paddingInlineStart={0}
      variant="unstyled"
    >
      <Flex flexDirection="column" padding={isSelected ? '21px' : '23px'}>
        <Icon as={icon} width="40px" height="40px" color="currentColor" />
        <Text
          textStyle="h5"
          width="15rem"
          marginTop="16px"
          whiteSpace="pre-wrap"
          noOfLines={1}
        >
          {title}
        </Text>
        <Text
          textStyle="body2"
          width="15rem"
          height="2.5rem"
          marginTop="8px"
          whiteSpace="pre-wrap"
          noOfLines={2}
        >
          {subtitle}
        </Text>
      </Flex>
    </Button>
  )
}

Tile.propTypes = {
  id: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}
