import { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import {
  sendGroupVouchers,
  updateGroupedVoucherDetails,
  getVouchersToPrint,
} from 'services/RedeemApi'

// Refer to useQuery here
// https://react-query.tanstack.com/docs/guides/queries#displaying-background-fetching-loading-states

export function useSendGroupedVouchers() {
  const { mutateAsync, status, data, error, reset, isLoading } = useMutation(
    sendGroupVouchers,
    {
      throwOnError: false,
      // On success, replace the cached query data of campaign voucher list by finding its index and
      // replacing it with the voucher object returned from sending successful
    }
  )

  const sendGroupedVouchers = useCallback(
    (groupId) => mutateAsync({ groupId }),
    [mutateAsync]
  )

  return {
    sendGroupedVouchers,
    isSendGroupedVouchersLoading: isLoading,
    sendGroupedVouchersStatus: status,
    sendGroupedVouchersResponse: data,
    sendGroupedVouchersError: error,
    resetSendGroupedVouchers: reset,
  }
}

export function useGetVouchersToPrint() {
  // TODO: Use react query
  return getVouchersToPrint
}

export function useUpdateGroupedVoucherDetails({ campaignId }) {
  const queryCache = useQueryClient()
  const { mutateAsync, status, data, error, reset, isLoading } = useMutation(
    updateGroupedVoucherDetails,
    {
      throwOnError: true,
      // TODO: Consider if not refetching is more appropriate
      onSuccess: () =>
        queryCache.invalidateQueries([campaignId, 'groupedVouchers']),
    }
  )

  const updateGroupedVoucherDetailsCallback = useCallback(
    ({ groupId, updateGroupParams }) =>
      mutateAsync({ groupId, updateGroupParams }),
    [mutateAsync]
  )

  return {
    updateGroupedVoucherDetails: updateGroupedVoucherDetailsCallback,
    isUpdateGroupedVoucherDetailsLoading: isLoading,
    updateGroupedVoucherDetailsStatus: status,
    updateGroupedVoucherDetailsResponse: data,
    updateGroupedVoucherDetailsError: error,
    resetUpdateGroupedVoucherDetails: reset,
  }
}
