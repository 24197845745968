import React, { useCallback, useContext } from 'react'
import { getErrorMessage } from 'services/utils'
import SendVoucherGroupModal from './SendVoucherGroupModal'
import { VouchersTabContext } from '../../VouchersTabContext'

type SendVoucherGroupModalContainerProps = Record<string, never>

const SendVoucherGroupModalContainer: React.FC<SendVoucherGroupModalContainerProps> = () => {
  const {
    isSendGroupedVoucherModalOpen,
    selectedGroupToSend,
    closeSendGroupedVoucherModal,
    sendGroupedVouchersStatus,
    sendGroupedVouchersError,
    onSendGroupedVouchers,
  } = useContext(VouchersTabContext)
  const onPrimaryClick = useCallback(
    () => onSendGroupedVouchers(selectedGroupToSend.id),
    [onSendGroupedVouchers, selectedGroupToSend]
  )
  return (
    <SendVoucherGroupModal
      onPrimaryClick={onPrimaryClick}
      sendStatus={sendGroupedVouchersStatus}
      isOpen={isSendGroupedVoucherModalOpen}
      onCloseClick={closeSendGroupedVoucherModal}
      group={selectedGroupToSend}
      error={getErrorMessage(sendGroupedVouchersError)}
    />
  )
}

export default SendVoucherGroupModalContainer
