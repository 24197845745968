import React from 'react'
import PropTypes from 'prop-types'
import redeemLogo from 'img/redeem-logo.svg'

import { useAuthenticationState } from 'data/Authentication'

import { Button, HStack, Image, Text } from '@chakra-ui/react'

// Returns an AppHeader with email provided in props, else within user object
export default function AppHeader({ email }) {
  const { setAuthStateWithUser, user } = useAuthenticationState()

  const onLogout = () => {
    // TODO:
    // Shift this somewhere else, do server call to invalidate perhaps
    setAuthStateWithUser(null)
  }

  return (
    <HStack
      align="center"
      justify="space-between"
      padding="20px 32px"
      background="white"
      borderStyle="solid"
      borderColor="neutral.300"
      borderBottomWidth="1px"
    >
      <Image width="170px" src={redeemLogo} />
      <HStack align="center" paddingRight="16px" spacing="16px">
        <Text textStyle="subhead1" color="neutral.700">
          {email || user.email}
        </Text>
        <Button onClick={onLogout} variant="outline">
          Logout
        </Button>
      </HStack>
    </HStack>
  )
}

AppHeader.propTypes = {
  email: PropTypes.string,
}
