import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { useGetVouchersToPrint } from 'hooks/Voucher'
import { printVouchers } from 'hooks/Print'
import { isVoided } from 'helpers/utils'
import { VouchersTabContext } from '../../../../VouchersTabContext'
import VoucherRow from './VoucherRow'

export default function VoucherRowContainer({ object, config }) {
  const {
    isPrintLoading,
    setIsPrintLoading,
    onSelectGroup,
    openUpdateVoucherGroupModal,
    isSendGroupedVouchersLoading,
    openSendGroupedVoucherModal,
    hasPermissionToSendVouchers,
    hasPermissionToUpdateVouchers,
    hasPermissionToPrintVouchers,
    campaignName,
    campaignExpiresAt,
    extraVoucherPrefix,
  } = useContext(VouchersTabContext)
  const onSendClick = useCallback(
    () => openSendGroupedVoucherModal(object.id),
    [openSendGroupedVoucherModal, object]
  )
  const onEditClick = useCallback(
    () => openUpdateVoucherGroupModal(object.id),
    [openUpdateVoucherGroupModal, object]
  )
  const isGroupVoided = isVoided(object)
  const onGetPrintVouchers = useGetVouchersToPrint()
  const onPrintClick = useCallback(() => {
    setIsPrintLoading(true)
    // Adds a delay so that spinner loads before
    // browser processes print.
    setTimeout(async () => {
      const { vouchers: vouchersToPrint } = await onGetPrintVouchers({
        groupId: object.id,
      })
      printVouchers({
        vouchers: vouchersToPrint,
        onPrintDialogOpen: () => {
          setIsPrintLoading(false)
        },
        name: object.name,
        campaignName,
        campaignExpiresAt,
        extraVoucherPrefix,
      })
    }, 1000)
  }, [object, onGetPrintVouchers, setIsPrintLoading, campaignName])
  return (
    <VoucherRow
      config={config}
      group={object}
      isPrintLoading={isPrintLoading}
      onSendClick={onSendClick}
      isSending={isSendGroupedVouchersLoading}
      onPrintClick={onPrintClick}
      onSelectGroup={onSelectGroup}
      onEditClick={onEditClick}
      canSend={hasPermissionToSendVouchers && !isGroupVoided}
      canUpdate={hasPermissionToUpdateVouchers && !isGroupVoided}
      canPrint={hasPermissionToPrintVouchers && !isGroupVoided}
    />
  )
}

VoucherRowContainer.propTypes = {
  object: PropTypes.objectOf(PropTypes.any).isRequired,
  config: PropTypes.arrayOf(PropTypes.object).isRequired,
}
