import { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import {
  createVoucherForCampaign,
  fetchAllVouchersByCampaignId,
} from 'services/RedeemApi'
import { VoucherType } from 'services/RedeemApi/types'
import usePaginatedQuery from './pagination'

// Refer to useQuery here
// https://react-query.tanstack.com/docs/guides/queries#displaying-background-fetching-loading-states

interface UseCampaignVouchersPaginatedProps {
  campaignId: string
  enabled?: boolean | (({ search }: { search: string }) => boolean)
}

export function useCampaignVouchersPaginated({
  campaignId,
  enabled = true,
}: UseCampaignVouchersPaginatedProps) {
  const {
    entries,
    fetchEntriesStatus,
    isFetchingEntries,
    isFetchingEntriesNextPage,
    isFetchingEntriesPreviousPage,
    fetchEntriesError,
    fetchEntriesCurrentSearchQuery,
    getNextPageOfEntries,
    getPreviousPageOfEntries,
    refreshFetchEntries,
    updateFetchEntriesSearchQuery,
  } = usePaginatedQuery<VoucherType>({
    apiFunction: (params) =>
      fetchAllVouchersByCampaignId({ ...params, campaignId }),
    queryKey: [campaignId, 'groupedVouchers'],
    enabled,
  })

  return {
    vouchers: entries,
    fetchVouchersByCampaignIdStatus: fetchEntriesStatus,
    isFetchingVouchersByCampaignId: isFetchingEntries,
    isFetchingVouchersByCampaignIdNextPage: isFetchingEntriesNextPage,
    isFetchingVouchersByCampaignIdPreviousPage: isFetchingEntriesPreviousPage,
    fetchVouchersByCampaignIdError: fetchEntriesError,
    fetchVouchersByCampaignCurrentSearchQuery: fetchEntriesCurrentSearchQuery,
    getNextPageOfVouchersByCampaignId: getNextPageOfEntries,
    getPreviousPageOfVouchersByCampaignId: getPreviousPageOfEntries,
    refreshFetchVouchersByCampaignId: refreshFetchEntries,
    updateFetchVouchersByCampaignIdSearchQuery: updateFetchEntriesSearchQuery,
  }
}

export function useCampaignVouchersSearchExists(campaignId: string) {
  return useCallback(
    async ({ block, floor, unit, postalCode, search, nric }) => {
      const response = await fetchAllVouchersByCampaignId({
        campaignId,
        block,
        floor,
        unit,
        postalCode,
        search,
        nric,
        limit: 1,
      })

      return !!response?.data?.length
    },
    [campaignId]
  )
}

export function useCreateCampaignVoucher(campaignId: string) {
  const queryCache = useQueryClient()
  const { mutateAsync, status, data, error, reset } = useMutation(
    createVoucherForCampaign,
    {
      onSuccess: () =>
        queryCache.invalidateQueries([campaignId, 'groupedVouchers']),
    }
  )

  const createCampaignVoucher = useCallback(
    (params) => mutateAsync({ campaignId, ...params }),
    [mutateAsync, campaignId]
  )

  return {
    createCampaignVoucher,
    createCampaignVoucherStatus: status,
    createCampaignVoucherResponse: data,
    createCampaignVoucherError: error,
    resetCreateCampaignVoucher: reset,
  }
}
