import React from 'react'
import {
  Icon,
  ModalBody,
  Button,
  Text,
  Box,
  Flex,
  Image,
  VStack,
} from '@chakra-ui/react'
import { BiRightArrowAlt } from 'react-icons/bi'
import singaporeFlag from 'img/singapore.png'
import { FormProvider, useForm } from 'react-hook-form'
import FormInput from 'components/FormInput'
import { VOUCHER_TYPES } from '../../constants'
import { CreateVoucherStoredFormContent } from '../../types'
import CreateVoucherStepTitle from '../CreateVoucherStepTitle'

type CreateVoucherRecipientDetailsStepProps = {
  selectedVoucherType?: VOUCHER_TYPES
  onPrimaryClick: (data: {
    voucherContactNumberParam: string
    voucherNameParam: string
  }) => void
  isPrimaryLoading?: boolean
  storedFormContent: CreateVoucherStoredFormContent
  campaignName?: string
}

const CreateVoucherRecipientDetailsStep: React.FC<CreateVoucherRecipientDetailsStepProps> = ({
  selectedVoucherType,
  onPrimaryClick,
  isPrimaryLoading,
  storedFormContent,
  campaignName,
}) => {
  const formMethods = useForm()
  const { handleSubmit } = formMethods

  const {
    voucherContactNumberParam: storedContactNumber,
    voucherNameParam: storedName,
  } = storedFormContent
  return (
    <FormProvider {...formMethods}>
      <ModalBody paddingX={0}>
        <Flex flexDirection="column" marginTop="32px" paddingX="48px">
          <CreateVoucherStepTitle campaignName={campaignName}>
            Recipient Details
          </CreateVoucherStepTitle>
        </Flex>
        <Box marginTop="40px">
          <form onSubmit={handleSubmit(onPrimaryClick)}>
            <VStack align="start" paddingX="48px" spacing="24px">
              <Text textStyle="h4" color="primary.500">
                Personal
              </Text>
              <FormInput
                defaultValue={storedName}
                name="voucherNameParam"
                label="Name"
                inputProps={{
                  placeholder: 'John Doe',
                  id: 'create-vouchers-name-input',
                  autoFocus: true,
                }}
              />
              <FormInput
                name="voucherContactNumberParam"
                defaultValue={storedContactNumber}
                label={
                  <>
                    <Flex alignItems="center">
                      Mobile number
                      {selectedVoucherType === VOUCHER_TYPES.PAPER && (
                        <Text
                          textStyle="body2"
                          marginLeft="8px"
                          color="neutral.700"
                        >
                          (optional)
                        </Text>
                      )}
                    </Flex>
                    {selectedVoucherType === VOUCHER_TYPES.PAPER && (
                      <Text textStyle="body2" color="neutral.700">
                        Leave mobile number blank if resident does not have a
                        phone
                      </Text>
                    )}
                  </>
                }
                inputProps={{
                  placeholder: '91234567',
                  id: 'create-vouchers-mobile-number-input',
                }}
                registerOptions={{
                  required:
                    selectedVoucherType === VOUCHER_TYPES.DIGITAL
                      ? 'Please enter a valid phone number'
                      : false,
                  maxLength: {
                    value: 8,
                    message: 'Please enter a valid 8 digit phone number',
                  },
                  minLength: {
                    value: 8,
                    message: 'Please enter a valid 8 digit phone number',
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Please enter a valid phone number',
                  },
                }}
                inputRightElement={
                  <Image height="16px" paddingRight="2px" src={singaporeFlag} />
                }
              />
            </VStack>
            <Box margin="48px">
              <Button
                colorScheme="primary"
                id="create-vouchers-recipient-details-next"
                isLoading={isPrimaryLoading}
                loadingText="Sending vouchers"
                rightIcon={
                  <Icon as={BiRightArrowAlt} width="1.5rem" height="1.5rem" />
                }
                spinnerPlacement="end"
                type="submit"
              >
                Next
              </Button>
            </Box>
          </form>
        </Box>
      </ModalBody>
    </FormProvider>
  )
}

export default CreateVoucherRecipientDetailsStep
