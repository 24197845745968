import React, { useContext } from 'react'

import VoucherSearch from './VoucherSearch'
import { VouchersTabContext } from '../../VouchersTabContext'

// TODO: Clean up the remaining toast examples
// import { useToast } from 'data/Toasts'
// import { TYPE } from 'components/Toast'

export default function VoucherSearchContainer() {
  const {
    filterVouchersValue: searchValue,
    setFilterVouchersValue: updateSearchValue,
  } = useContext(VouchersTabContext)
  return (
    <VoucherSearch
      searchValue={searchValue}
      onPrimaryClick={updateSearchValue}
    />
  )
}

VoucherSearchContainer.propTypes = {}
