import React, { useCallback, useContext } from 'react'
import VoucherDetailsDrawer from './VoucherDetailsDrawer'
import { VouchersTabContext } from '../../VouchersTabContext'

export default function VoucherDetailsDrawerContainer() {
  const {
    selectedGroup,
    onCloseGroup,
    onPrintUnusedVouchers,
    isPrintLoading,
    isSendGroupedVouchersLoading,
    openSendGroupedVoucherModal,
    openUpdateVoucherGroupModal,
    groupedVoucherEvents,
    isLoadingGroupedVoucherEvents,
    hasPermissionToSendVouchers,
    hasPermissionToPrintVouchers,
    hasPermissionToUpdateVouchers,
  } = useContext(VouchersTabContext)

  const onSendClick = useCallback(() => {
    if (!selectedGroup?.id) {
      return
    }
    openSendGroupedVoucherModal(selectedGroup.id)
  }, [openSendGroupedVoucherModal, selectedGroup])
  const onEditClick = useCallback(() => {
    if (!selectedGroup?.id) {
      return
    }
    openUpdateVoucherGroupModal(selectedGroup.id)
  }, [openUpdateVoucherGroupModal, selectedGroup])

  return (
    <VoucherDetailsDrawer
      isOpen={!!selectedGroup}
      onClose={onCloseGroup}
      selectedGroup={selectedGroup}
      onPrintUnusedVouchers={onPrintUnusedVouchers}
      isPrintLoading={isPrintLoading}
      onSendClick={onSendClick}
      isSending={isSendGroupedVouchersLoading}
      onEditClick={onEditClick}
      groupedVoucherEvents={groupedVoucherEvents}
      isLoadingGroupedVoucherEvents={isLoadingGroupedVoucherEvents}
      hasPermissionToSendVouchers={hasPermissionToSendVouchers}
      hasPermissionToPrintVouchers={hasPermissionToPrintVouchers}
      hasPermissionToUpdateVouchers={hasPermissionToUpdateVouchers}
    />
  )
}

VoucherDetailsDrawerContainer.propTypes = {}
