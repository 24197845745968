import React, { useContext } from 'react'
import { UserActions } from 'constants/permissions'
import { useAuthenticationState } from 'data/Authentication'
import VoucherHeader from './VoucherHeader'
import { VouchersTabContext } from '../../VouchersTabContext'

type VoucherHeaderContainerProps = Record<string, never>

const VoucherHeaderContainer: React.FC<VoucherHeaderContainerProps> = () => {
  const {
    onOpenDownloadTransactionsModal,
    onClickCreateNew,
    onClickBulkCreate,
    vouchersTotalCount,
    campaignId,
    onDownloadCampaignVoucherReport,
    onDownloadCampaignVoucherLinks,
    isDownloadCampaignVoucherLinksLoading,
  } = useContext(VouchersTabContext)
  const { permissions } = useAuthenticationState()
  const hasPermissionToCreateVouchers = permissions[
    campaignId
  ]?.actions?.includes(UserActions.CreateGroupedVouchers)
  const hasPermissionToBulkCreateVouchers = permissions[
    campaignId
  ]?.actions?.includes(UserActions.BulkCreateGroupedVouchers)
  const hasPermissionToDownloadVouchers = permissions[
    campaignId
  ]?.actions?.includes(UserActions.DownloadVoucherReport)
  const hasPermissionToDownloadTransactions = permissions[
    campaignId
  ]?.actions?.includes(UserActions.ListTransactionReports)
  const hasPermissionToDownloadCampaignVoucherLinks = permissions[
    campaignId
  ]?.actions?.includes(UserActions.DownloadCampaignVoucherLinks)

  return (
    <VoucherHeader
      // Here we are passing null to double confirm that someone cannot create
      // vouchers if they are missing permissions here.
      onClickCreateNew={hasPermissionToCreateVouchers ? onClickCreateNew : null}
      onClickBulkCreate={
        hasPermissionToBulkCreateVouchers ? onClickBulkCreate : null
      }
      canCreateVouchers={hasPermissionToCreateVouchers}
      canBulkCreateVouchers={hasPermissionToBulkCreateVouchers}
      vouchersTotalCount={vouchersTotalCount}
      onClickOpenDownloadTransactionModal={onOpenDownloadTransactionsModal}
      canDownloadTransactions={hasPermissionToDownloadTransactions}
      canDownloadVouchers={hasPermissionToDownloadVouchers}
      onClickDownloadVouchers={onDownloadCampaignVoucherReport}
      canDownloadCampaignVoucherLinks={
        hasPermissionToDownloadCampaignVoucherLinks
      }
      onClickDownloadCampaignVoucherLinks={onDownloadCampaignVoucherLinks}
      isDownloadCampaignVoucherLinksLoading={
        isDownloadCampaignVoucherLinksLoading
      }
    />
  )
}

export default VoucherHeaderContainer
