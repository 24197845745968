import { useCallback } from 'react'
import _ from 'lodash'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  fetchAllCampaigns,
  createCampaign,
  updateCampaignDetails,
  updateCampaignVoucherDetails,
} from 'services/RedeemApi'
import {
  UpdateCampaignDetailsProp,
  UpdateCampaignVoucherDetailsProp,
} from 'services/RedeemApi/types'

export default function useCampaigns() {
  const { data: response, refetch, status, isFetching, error } = useQuery(
    'allCampaigns',
    fetchAllCampaigns
  )

  const campaigns = _.get(response, 'data', [])

  const campaignsSortedByCreatedAt = _.orderBy(campaigns, 'updatedAt', ['desc'])

  return {
    campaigns: campaignsSortedByCreatedAt,
    fetchCampaigns: refetch,
    fetchCampaignsStatus: status,
    isFetchingCampaigns: isFetching,
    fetchCampaignsError: error,
  }
}

export function useCreateCampaign() {
  const queryCache = useQueryClient()
  const { mutateAsync, status, data, error, reset } = useMutation(
    createCampaign,
    {
      onError: () => {
        // Previously, implementation was from v2 https://github.com/TanStack/query/blob/2.x/docs/src/pages/docs/api.md and implementation
        // details was https://github.com/TanStack/query/blob/2.x/src/react/useMutation.ts#L182. Currently, keeping the old behaviour in v3
        // of throwing a generic error.
        throw new Error('unable to create campaign')
      },
      onSuccess: () => queryCache.invalidateQueries('allCampaigns'),
    }
  )

  const createCampaignCallback = useCallback((params) => mutateAsync(params), [
    mutateAsync,
  ])

  return {
    createCampaign: createCampaignCallback,
    createCampaignStatus: status,
    createCampaignResponse: data,
    createCampaignError: error,
    resetCreateCampaign: reset,
  }
}

export function useUpdateCampaignDetails(campaignId: string) {
  const queryCache = useQueryClient()
  const { mutateAsync, error, isLoading } = useMutation(
    (updatedCampaignDetails: UpdateCampaignDetailsProp) =>
      updateCampaignDetails(campaignId, updatedCampaignDetails),
    {
      onError: () => {
        // Previously, implementation was from v2 https://github.com/TanStack/query/blob/2.x/docs/src/pages/docs/api.md and implementation
        // details was https://github.com/TanStack/query/blob/2.x/src/react/useMutation.ts#L182. Currently, keeping the old behaviour in v3
        // of throwing a generic error.
        throw new Error('unable to update campaign details')
      },
      // Invalidate the stale campaign data if the campaign updated successfully
      onSuccess: () => queryCache.invalidateQueries(campaignId),
    }
  )

  return {
    updateCampaignDetails: mutateAsync,
    updateCampaignDetailsError: error,
    isUpdateCampaignDetailsLoading: isLoading,
  }
}

export function useUpdateCampaignVoucherDetails(campaignId: string) {
  const queryCache = useQueryClient()
  const { mutateAsync, error, isLoading } = useMutation(
    (updatedCampaignVoucherDetails: UpdateCampaignVoucherDetailsProp) =>
      updateCampaignVoucherDetails(campaignId, updatedCampaignVoucherDetails),
    {
      onError: () => {
        // Previously, implementation was from v2 https://github.com/TanStack/query/blob/2.x/docs/src/pages/docs/api.md and implementation
        // details was https://github.com/TanStack/query/blob/2.x/src/react/useMutation.ts#L182. Currently, keeping the old behaviour in v3
        // of throwing a generic error.
        throw new Error('unable to update campaign voucher details')
      },
      // Invalidate the stale campaign data if the campaign updated successfully
      onSuccess: () => queryCache.invalidateQueries(campaignId),
    }
  )

  return {
    updateCampaignVoucherDetails: mutateAsync,
    updateCampaignVoucherDetailsError: error,
    isUpdateCampaignVoucherDetailsLoading: isLoading,
  }
}
