import { Flex, Image, Spinner, Button } from '@chakra-ui/react'
import React from 'react'
import checkCircleSolidSuccess from 'img/check-circle-solid-success.svg'
import { formatAddress } from 'helpers/utils'
import RecipientDetails from '../../../RecipientDetails'
import { VOUCHER_TYPES } from '../../constants'
import { CreateVoucherStoredFormContent } from '../../types'
import CreateVoucherStepTitle from '../CreateVoucherStepTitle'

type CreateVoucherSendFinalStepProps = {
  storedFormContent: CreateVoucherStoredFormContent
  selectedVoucherType: VOUCHER_TYPES
  isPrimaryLoading: boolean
  onPrimaryClick: () => void
  campaignName?: string
}

const CreateVoucherSendFinalStep: React.FC<CreateVoucherSendFinalStepProps> = ({
  storedFormContent: {
    voucherBlockNumberParam,
    voucherStreetNameParam,
    voucherPostalCodeParam,
    voucherFloorNumberParam,
    voucherUnitNumberParam,
    voucherNameParam,
    voucherNricParam,
    voucherContactNumberParam,
  },
  selectedVoucherType,
  isPrimaryLoading,
  onPrimaryClick,
  campaignName,
}) => {
  return (
    <Flex
      alignItems="stretch"
      flexDirection="column"
      paddingBottom="48px"
      paddingX="48px"
    >
      <Flex flexDirection="column" marginTop="32px">
        {isPrimaryLoading && (
          <Spinner width="48px" height="48px" marginBottom="36px" />
        )}
        {!isPrimaryLoading && (
          <Image
            width="40px"
            height="40px"
            marginBottom="24px"
            src={checkCircleSolidSuccess}
          />
        )}
      </Flex>
      {selectedVoucherType === VOUCHER_TYPES.DIGITAL && (
        <CreateVoucherStepTitle campaignName={campaignName}>
          Sending vouchers by SMS
        </CreateVoucherStepTitle>
      )}
      {selectedVoucherType === VOUCHER_TYPES.PAPER && (
        <CreateVoucherStepTitle campaignName={campaignName}>
          Printing vouchers
        </CreateVoucherStepTitle>
      )}
      <RecipientDetails
        marginTop="24px"
        name={voucherNameParam}
        nric={voucherNricParam}
        address={
          !!voucherBlockNumberParam &&
          !!voucherStreetNameParam &&
          !!voucherPostalCodeParam
            ? formatAddress({
                blockNumber: voucherBlockNumberParam,
                streetName: voucherStreetNameParam,
                postalCode: voucherPostalCodeParam,
                floorNumber: voucherFloorNumberParam,
                unitNumber: voucherUnitNumberParam,
              })
            : undefined
        }
        contactNumber={voucherContactNumberParam}
      />
      {!isPrimaryLoading && (
        <Button
          alignSelf="flex-start"
          marginTop="40px"
          colorScheme="primary"
          onClick={onPrimaryClick}
        >
          Back to home
        </Button>
      )}
    </Flex>
  )
}

export default CreateVoucherSendFinalStep
