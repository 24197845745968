import { useDownloadCampaignTransactionReport } from 'hooks/CampaignReports'
import React, { useContext } from 'react'
import { VouchersTabContext } from '../../../../VouchersTabContext'
import DownloadTransactionReportRow from './DownloadTransactionReportRow'

type DownloadTransactionReportRowContainerProps = {
  report: any // TODO: Stricter typing
}

const DownloadTransactionReportRowContainer: React.FC<DownloadTransactionReportRowContainerProps> = ({
  report,
}) => {
  const { campaignId } = useContext(VouchersTabContext)
  const {
    downloadCampaignTransactionReport,
    isDownloadCampaignTransactionReportLoading,
  } = useDownloadCampaignTransactionReport({ campaignId, key: report.key })
  return (
    <DownloadTransactionReportRow
      date={report.reportDate}
      onPrimaryClick={downloadCampaignTransactionReport}
      isPrimaryLoading={isDownloadCampaignTransactionReportLoading}
    />
  )
}

export default DownloadTransactionReportRowContainer
