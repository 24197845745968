import type { JsonObject } from 'type-fest'

export type VoucherValue = { quantity: number; value: number; type: string }
type VoucherColour = { colour: string; value: string | number }

export type VerifyOtpResponse = {
  user: {
    id: string
    contactNumber: string | null
    email: string | null
    name: string | null
  }
  token: string
}

export type UserRole = {
  actorId: string
  resourceId: string
  roles: string[]
  actions: string[]
}

export type ListUserRolesForUserResponse = {
  data: UserRole[]
}

type CampaignValidity =
  | 'campaign_not_started'
  | 'campaign_ended'
  | 'campaign_valid'

export type CampaignVisibility = 'public' | 'private'

enum CampaignEligibility {
  signup_whitelist_nric = 'signup_whitelist_nric',
  signup_conditions_address = 'signup_conditions_address',
  no_signup = 'no_signup',
}

// This is not the full list of return fields from the backend, but you can pick what you need in the FE
export type Campaign = {
  id: string
  name: string | null
  description: string | null
  organiserName: string | null
  organiserEmail: string | null
  advisoryUrl: string | null
  organiserFeedbackUrl: string | null
  logoUrl: string | null
  colour: string | null
  owner: string | null
  defaultVouchers: VoucherValue[] | null
  voucherColours: VoucherColour[] | null
  isDeleted: boolean
  metadata: Record<any, any>
  createdAt: string
  updatedAt: string
  visibility: CampaignVisibility
  eligibility: CampaignEligibility
  validity: CampaignValidity
  validityStart: string
  validityEnd: string
  merchantListUrl: string | null
  organiserLocation: string | null
  customCredentialsEnabled: boolean
  extraQrPrefix: string | null
}

export type CampaignEvent = {
  id: number
  event: string
  campaignId: string
  actorId: string
  data: JsonObject | null
  createdAt: string
  updatedAt: string
  actorEmail?: string
}

export type MerchantEvent = {
  id: number
  event: string
  merchantId: string
  actorId: string
  actorEmail: string | null
  data: JsonObject | null
  createdAt: string
  updatedAt: string
}

export type PaginatedResponse<T> = {
  pageInfo: {
    hasNextPage: boolean
    hasPreviousPage: boolean
    startCursor: string
    endCursor: string
  }
  data: T[]
}

export type CampaignEventResponse = PaginatedResponse<CampaignEvent>
export type MerchantEventResponse = PaginatedResponse<MerchantEvent>

export type BaseWhitelistMetaData = {
  numberOfRows: number
  numberOfDuplicates: number
  numberOfInvalidNrics: number
}

export const enum WhitelistCheckValidityResponseType {
  SUCCESS,
  INVALID_PARAMS,
  ERROR,
}

export type BaseBulkCreateMetaData = {
  numberOfRows: number
  numberOfEmptyRows: number
  numberOfInvalidNric: number
  numberOfInvalidMobileNumber: number
  numberOfDuplicateMobileNumber: number
  numberOfDuplicateName: number
  numberOfDuplicateNric: number
  numberOfMissingMobileNumber: number
  numberOfMissingName: number
  numberOfMissingNric: number
}

export const enum BulkCreateCheckValidityResponseType {
  CHECKING_SUCCESS,
  INVALID_PARAMS,
  ERROR,
}

export type CheckWhitelistResponse = {
  href: string
  whitelistMetadata: BaseWhitelistMetaData
  hasWhitelistError: boolean
}

export type CheckBulkCreateResponse = {
  href: string
  bulkCreateMetadata: BaseBulkCreateMetaData
  hasBulkCreateError: boolean
  haveExtraDetails: boolean
}

export type SubmitBulkCreateResponse = {
  jobId: string
}

export enum BulkCreateJobStatus {
  not_started = 'not_started',
  started = 'started',
  success = 'success',
  failure = 'failure',
}

export type CheckBulkCreateJobStatusResponse = {
  jobStatus: BulkCreateJobStatus
  numSuccessfulCreations: number
  numOfGroupedVouchersToCreate: number
}

// Types that are manually retrieved from the backend.
// Note that these types below might be outdated

type ListVoucherResponseObject = {
  id: string
  label: string | null
  state: string
  metadata: Record<any, any>
  lastRedeemedTimestamp: string | null
  voucherValue: number
  groupId: string | null
  type: string | null
}

type AddressBody = {
  postalCode: string | null
  block: string | null
  floor: string | null
  unit: string | null
  street: string | null
}

export type VoucherType = AddressBody & {
  id: string
  campaignId: string
  name: string | null
  contactNumber: string | null
  nric: string | null
  metadata: Record<string, unknown>
  vouchers: ListVoucherResponseObject[]
}

export type UpdateCampaignDetailsProp = {
  campaignName?: string
  campaignDescription?: string
  campaignOrganiserName?: string
  campaignOrganiserEmail?: string | null
  campaignLogoUrl?: string | null
  campaignMerchantListUrl?: string | null
  campaignOrganiserFeedbackUrl?: string | null
  campaignOrganiserLocation?: string | null
}

export type UpdateCampaignVoucherDetailsProp = {
  campaignAdvisoryUrl?: string | null
  campaignLogoUrl?: string
  campaignColour?: string
  campaignDefaultVouchers?: (Omit<VoucherValue, 'type'> | VoucherValue)[]
  campaignVoucherColours?: VoucherColour[]
}

export type UpdateCampaignProps = {
  campaignName?: string
  campaignDescription?: string
  campaignOrganiserName?: string
  campaignOrganiserEmail?: string | null
  campaignAdvisoryUrl?: string | null
  campaignOrganiserFeedbackUrl?: string | null
  campaignLogoUrl?: string | null
  campaignMerchantListUrl?: string | null
  campaignOrganiserLocation?: string | null
  campaignColour?: string
  campaignDefaultVouchers?: (Omit<VoucherValue, 'type'> | VoucherValue)[]
  campaignVoucherColours?: VoucherColour[]
}

export type CampaignSmsUsage = {
  smsUsage: number
}

export type TwilioCredentials = {
  accountSid: string
  authToken: string
  messagingServiceSid: string
}

export type DownloadCampaignVoucherLinksResponse = {
  downloadUrl: string
}

export type MetricIdentifierToValue = {
  identifier: string
  value: number
}

type SingleValueMetric = {
  header: string
  isSingleValue: true
  value: number
  breakdown?: MetricIdentifierToValue[] | null
}

// Denotes graph
type MultipleValueMetric = {
  header: string
  isSingleValue: false
  value: MetricIdentifierToValue[]
}

export type Metric = SingleValueMetric | MultipleValueMetric

export type CampaignMetricDto = {
  // Vouchers Related
  totalVouchersClaimed: Metric
  totalNumberOfVouchersWithAtLeastOneRedemption: Metric
  totalVouchersClaimedPerMonth: Metric
  totalVouchersClaimedPerDay: Metric
  // Transaction Related
  totalNumberOfTransactions: Metric
  totalValueOfTransactions: Metric
  averageTransactionValue: Metric
  totalValueOfTransactionsPerMonth: Metric
  totalValueOfTransactionsPerDay: Metric
  // Merchants Related
  totalNumberOfMerchants: Metric
  merchantTransactionValues: Metric
  totalNumberOfMerchantsWithAtLeastOneRedemption: Metric
  latestDataDateRetrieved: string
}
