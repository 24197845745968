import React from 'react'
import VouchersTab from './VouchersTab'

import { VouchersTabProvider } from './VouchersTabContext'

export default function VouchersTabContainer() {
  return (
    <VouchersTabProvider>
      <VouchersTab />
    </VouchersTabProvider>
  )
}
