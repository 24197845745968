import React, { useCallback, useEffect, useRef } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  Spinner,
  Flex,
} from '@chakra-ui/react'
import ModalCloseButton from 'components/ModalCloseButton'
import { Step, Steps } from 'chakra-ui-steps'
import { VoucherValue } from 'services/RedeemApi/types'
import CreateVoucherDetailsStep from './components/CreateVoucherDetailsStep'
import CreateVoucherRecipientDetailsStep from './components/CreateVoucherRecipientDetailsStep'
import ConfirmRecipientStep from './components/ConfirmRecipientStep'
import CreateVoucherSendFinalStep from './components/CreateVoucherSendFinalStep'
import { CreateVoucherStoredFormContent } from './types'
import { VOUCHER_TYPES } from './constants'

const CreateVoucherCheckExistingStep = React.lazy(
  () => import('./components/CreateVoucherCheckExistingStep')
)

type CreateVoucherModalProps = {
  isOpen: boolean
  onPrimaryClick: () => void
  isPrimaryLoading: boolean
  onCloseClick: () => void
  defaultVouchers: VoucherValue[]
  campaignName: string
  selectedVoucherType: VOUCHER_TYPES
  setSelectedVoucherType: (x: VOUCHER_TYPES) => void
  currentStep: number
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  isUsePrefill: boolean
  setIsUsePrefill: () => void
  onAddFormContent: (data: Partial<CreateVoucherStoredFormContent>) => void
  storedFormContent: CreateVoucherStoredFormContent
  onApplySearch: (value: string) => void
  getIsVoucherGroupsMatchingQueryExists: (query: string) => Promise<boolean>
  checkIfNricIsNotInWhitelistForCampaign: ({
    nric,
  }: {
    nric: string
  }) => Promise<boolean>
}

const CreateVoucherModal: React.FC<CreateVoucherModalProps> = ({
  isOpen,
  onPrimaryClick,
  isPrimaryLoading,
  defaultVouchers,
  onCloseClick,
  campaignName,
  selectedVoucherType,
  setSelectedVoucherType,
  currentStep,
  setCurrentStep,
  isUsePrefill,
  setIsUsePrefill,
  onAddFormContent,
  storedFormContent,
  onApplySearch,
  getIsVoucherGroupsMatchingQueryExists,
  checkIfNricIsNotInWhitelistForCampaign,
}) => {
  const hasDefaultVouchers = Boolean(defaultVouchers && defaultVouchers.length)
  const modalContentRef = useRef<HTMLElement>(null)
  const advanceStep = useCallback(
    (change: number) => setCurrentStep((step) => step + change),
    [setCurrentStep]
  )
  const scrollToBottom = useCallback(() => {
    const modalContentContainer = modalContentRef.current?.parentElement
    if (modalContentContainer) {
      modalContentContainer.scrollTop = modalContentContainer.scrollHeight
    }
  }, [])
  useEffect(() => {
    const modalContentContainer = modalContentRef.current?.parentElement
    /// No need to scroll to top on reset state
    if (currentStep === 0 || !modalContentContainer) {
      return
    }
    modalContentContainer.scrollTop = 0
  }, [currentStep])
  return (
    <Modal
      colorScheme="primary"
      isOpen={isOpen}
      onClose={onCloseClick}
      size="create"
    >
      <ModalOverlay />
      <ModalContent ref={modalContentRef}>
        <ModalHeader>Check and Add Recipient</ModalHeader>
        <ModalCloseButton onClick={onCloseClick} />
        <ModalBody paddingX={0}>
          {currentStep < 4 && (
            <Steps colorScheme="primary" activeStep={currentStep}>
              <Step label="Voucher">
                <CreateVoucherDetailsStep
                  setSelectedVoucherType={setSelectedVoucherType}
                  selectedVoucherType={selectedVoucherType}
                  campaignName={campaignName}
                  defaultVouchers={defaultVouchers}
                  hasDefaultVouchers={hasDefaultVouchers}
                  onClickNext={() => advanceStep(1)}
                />
              </Step>
              <Step label="NRIC / Address">
                {' '}
                <React.Suspense
                  fallback={
                    <ModalBody minHeight="512px">
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        minHeight="inherit"
                      >
                        <Spinner colorScheme="primary" size="xl" />
                      </Flex>
                    </ModalBody>
                  }
                >
                  <CreateVoucherCheckExistingStep
                    storedFormContent={storedFormContent}
                    isUsePrefill={isUsePrefill}
                    setIsUsePrefill={setIsUsePrefill}
                    onPrimaryClick={(data) => {
                      onAddFormContent(data)
                      advanceStep(1)
                    }}
                    campaignName={campaignName}
                    onApplySearch={onApplySearch}
                    scrollToBottom={scrollToBottom}
                    getIsVoucherGroupsMatchingQueryExists={
                      getIsVoucherGroupsMatchingQueryExists
                    }
                    checkIfNricIsNotInWhitelistForCampaign={
                      checkIfNricIsNotInWhitelistForCampaign
                    }
                  />
                </React.Suspense>
              </Step>
              <Step label="Name / Number">
                <CreateVoucherRecipientDetailsStep
                  storedFormContent={storedFormContent}
                  selectedVoucherType={selectedVoucherType}
                  campaignName={campaignName}
                  onPrimaryClick={(data) => {
                    onAddFormContent(data)
                    advanceStep(1)
                  }}
                  isPrimaryLoading={isPrimaryLoading}
                />
              </Step>
              <Step label="Confirmation">
                <ConfirmRecipientStep
                  onClickBack={() => advanceStep(-2)}
                  selectedVoucherType={selectedVoucherType}
                  storedFormContent={storedFormContent}
                  campaignName={campaignName}
                  onPrimaryClick={() => {
                    advanceStep(1)
                    onPrimaryClick()
                  }}
                />
              </Step>
            </Steps>
          )}
          {currentStep === 4 && (
            <CreateVoucherSendFinalStep
              campaignName={campaignName}
              selectedVoucherType={selectedVoucherType}
              storedFormContent={storedFormContent}
              onPrimaryClick={onCloseClick}
              isPrimaryLoading={isPrimaryLoading}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(CreateVoucherModal)
