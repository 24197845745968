import React from 'react'
import {
  Icon,
  ModalBody,
  Button,
  Text,
  Divider,
  Switch,
  VStack,
  HStack,
} from '@chakra-ui/react'
import _ from 'lodash'
import { BiMobileAlt, BiNote, BiRightArrowAlt } from 'react-icons/bi'
import { VoucherValue } from 'services/RedeemApi/types'
import Tile from '../../../../../../../../../components/Tile'
import { VOUCHER_TYPES } from '../../constants'
import CreateVoucherStepTitle from '../CreateVoucherStepTitle'

type CreateVoucherDetailsStepProps = {
  setSelectedVoucherType: (x: VOUCHER_TYPES) => void
  selectedVoucherType: VOUCHER_TYPES
  onClickNext: () => void
  campaignName: string
  defaultVouchers?: VoucherValue[]
  hasDefaultVouchers?: boolean
}

const CreateVoucherDetailsStep: React.FC<CreateVoucherDetailsStepProps> = ({
  setSelectedVoucherType,
  selectedVoucherType,
  campaignName,
  defaultVouchers,
  hasDefaultVouchers,
  onClickNext,
}) => {
  return (
    <ModalBody padding="48px" paddingTop="32px">
      <CreateVoucherStepTitle campaignName={campaignName}>
        Voucher details
      </CreateVoucherStepTitle>
      <VStack align="start" flexDirection="column" margin="48px 0">
        <Text textStyle="h4">Voucher Type</Text>
        <HStack marginTop="16px" spacing="8px">
          <Tile
            id="paper-voucher-tile"
            icon={BiNote}
            title="Paper voucher"
            subtitle="Print voucher for recipient"
            onClick={() => setSelectedVoucherType(VOUCHER_TYPES.PAPER)}
            isSelected={selectedVoucherType === VOUCHER_TYPES.PAPER}
          />
          <Tile
            id="digital-voucher-tile"
            icon={BiMobileAlt}
            title="Digital voucher"
            subtitle="Send voucher link to recipient via SMS"
            onClick={() => setSelectedVoucherType(VOUCHER_TYPES.DIGITAL)}
            isSelected={selectedVoucherType === VOUCHER_TYPES.DIGITAL}
          />
        </HStack>
      </VStack>
      <Divider />
      <HStack
        justifyContent="space-between"
        alignSelf="flex-start"
        width="100%"
        marginTop="48px"
      >
        <VStack align="start" flexDirection="column">
          <Text textStyle="h4" color="neutral.900">
            Voucher denominations
          </Text>
          <Text
            textStyle="body1"
            maxWidth="30rem"
            marginTop="8px"
            color="neutral.800"
          >
            Use {campaignName} vouchers (
            {_(defaultVouchers)
              .map(
                ({ quantity, value, type }) =>
                  `${quantity} $${value} ${_.isNil(type) ? '' : type} voucher${
                    quantity === 1 ? '' : 's'
                  }`
              )
              .join(', ')}
            )
          </Text>
        </VStack>
        <Switch
          alignSelf="flex-end"
          _disabled={{
            opacity: 1,
          }}
          colorScheme="success"
          isChecked
          isDisabled
          isReadOnly
          size="lg"
          variant="coloredThumb"
        />
      </HStack>
      <Button
        marginTop="40px"
        colorScheme="primary"
        disabled={!hasDefaultVouchers || !selectedVoucherType}
        id="create-vouchers-details-next"
        onClick={onClickNext}
        rightIcon={<Icon as={BiRightArrowAlt} width="1.5rem" height="1.5rem" />}
      >
        Next
      </Button>
    </ModalBody>
  )
}

export default CreateVoucherDetailsStep
