import { useCampaignQuery, useCampaignStatsQuery } from 'hooks/Campaign'
import React, { useContext, createContext } from 'react'
import { useParams } from 'react-router-dom'
import {
  Campaign,
  CampaignVisibility,
  VoucherValue,
} from 'services/RedeemApi/types'
import { CAMPAIGN_ELIGIBILITY } from '../Campaign/constants'

const CampaignContext = createContext<
  | {
      campaign: Campaign | undefined
      campaignId: string
      campaignName: string
      campaignMetadata: Record<any, any>
      isBulkCreateCampaign: boolean
      hasWhitelist: boolean
      campaignVisibility: CampaignVisibility
      campaignAdvisoryUrl: string
      campaignOrganiserFeedbackUrl: string
      campaignDefaultVouchers: VoucherValue[] | null
      campaignDescription: string
      campaignOrganiserName: string
      campaignOrganiserEmail: string
      campaignLogoUrl: string
      campaignMerchantListUrl: string
      campaignOrganiserLocation: string
      campaignValidityEnd: string
      isCustomTwilioCredentialsEnabled: boolean
      totalNumberOfMerchants: number
      totalGroupedVouchersCount: number
      extraQrPrefix: string | null
    }
  | undefined
>(undefined)

type CampaignProviderProps = { children: React.ReactNode }

function CampaignProvider({ children }: CampaignProviderProps) {
  const { campaignId } = useParams<{ campaignId: string }>()
  const { campaign } = useCampaignQuery(campaignId)
  const { campaignStats } = useCampaignStatsQuery(campaignId)

  const campaignName = campaign?.name || '' // This is a weird typing from lodash
  const campaignMetadata = campaign?.metadata || ({} as Record<any, any>)
  const campaignEligibility = campaign?.eligibility
  const isBulkCreateCampaign =
    campaign?.eligibility === CAMPAIGN_ELIGIBILITY.NO_SIGNUP
  const hasWhitelist =
    campaignEligibility === CAMPAIGN_ELIGIBILITY.SIGNUP_WHITELIST_NRIC
  const campaignVisibility = campaign?.visibility || 'private' // this is the default even from our backend
  const campaignAdvisoryUrl = campaign?.advisoryUrl || ''
  const campaignOrganiserFeedbackUrl = campaign?.organiserFeedbackUrl || ''
  const campaignDefaultVouchers = campaign?.defaultVouchers || null
  const campaignDescription = campaign?.description || ''
  const campaignOrganiserName = campaign?.organiserName || ''
  const campaignOrganiserEmail = campaign?.organiserEmail || ''
  const campaignLogoUrl = campaign?.logoUrl || ''
  const campaignMerchantListUrl = campaign?.merchantListUrl || ''
  const campaignOrganiserLocation = campaign?.organiserLocation || ''
  const campaignValidityEnd = campaign?.validityEnd || ''
  const isCustomTwilioCredentialsEnabled =
    campaign?.customCredentialsEnabled || false // default to false always
  const totalNumberOfMerchants = campaignStats?.totalNumberOfMerchantsCount || 0
  const totalGroupedVouchersCount =
    campaignStats?.totalGroupedVouchersCount || 0
  const extraQrPrefix = campaign?.extraQrPrefix || null

  const value = {
    campaign,
    campaignId,
    campaignName,
    isBulkCreateCampaign,
    campaignMetadata,
    hasWhitelist,
    campaignVisibility,
    campaignAdvisoryUrl,
    campaignOrganiserFeedbackUrl,
    campaignDefaultVouchers,
    campaignDescription,
    campaignOrganiserName,
    campaignOrganiserEmail,
    campaignLogoUrl,
    campaignMerchantListUrl,
    campaignOrganiserLocation,
    campaignValidityEnd,
    isCustomTwilioCredentialsEnabled,
    totalNumberOfMerchants,
    totalGroupedVouchersCount,
    extraQrPrefix,
  }

  return (
    <CampaignContext.Provider value={value}>
      {children}
    </CampaignContext.Provider>
  )
}

function useCampaignContext() {
  const context = useContext(CampaignContext)
  if (context === undefined) {
    throw new Error('useCampaignContext must be used inside a CampaignProvider')
  }
  return context
}

export { useCampaignContext, CampaignProvider }
