import { Flex, Text, HStack, Icon, Button } from '@chakra-ui/react'
import { formatAddress } from 'helpers/utils'
import React from 'react'
import { BiLeftArrowAlt } from 'react-icons/bi'
import RecipientDetails from '../../../RecipientDetails'
import { VOUCHER_TYPES } from '../../constants'
import { CreateVoucherStoredFormContent } from '../../types'
import CreateVoucherStepTitle from '../CreateVoucherStepTitle'

type ConfirmRecipientStepProps = {
  onClickBack: () => void
  onPrimaryClick: () => void
  selectedVoucherType: VOUCHER_TYPES
  storedFormContent: CreateVoucherStoredFormContent
  campaignName?: string
}

const ConfirmRecipientStep: React.FC<ConfirmRecipientStepProps> = ({
  onClickBack,
  onPrimaryClick,
  selectedVoucherType,
  storedFormContent: {
    voucherBlockNumberParam,
    voucherStreetNameParam,
    voucherPostalCodeParam,
    voucherFloorNumberParam,
    voucherUnitNumberParam,
    voucherNameParam,
    voucherNricParam,
    voucherContactNumberParam,
  },
  campaignName,
}) => {
  return (
    <Flex alignItems="stretch" flexDirection="column" paddingX="48px">
      <Flex flexDirection="column" marginTop="32px">
        <Button
          alignSelf="flex-start"
          textDecoration="none"
          data-dd-action-name="Create vouchers edit button"
          onClick={onClickBack}
          variant="unstyled"
        >
          <HStack>
            <Icon as={BiLeftArrowAlt} width="1.5rem" height="1.5rem" />
            <Text>Edit details</Text>
          </HStack>
        </Button>
      </Flex>
      <CreateVoucherStepTitle campaignName={campaignName} marginTop="36px">
        Confirm recipient details
      </CreateVoucherStepTitle>
      <RecipientDetails
        marginTop="24px"
        name={voucherNameParam}
        nric={voucherNricParam}
        emptyNricWarningText="No NRIC Provided. Click 'Edit details' above to input."
        address={
          !!voucherBlockNumberParam &&
          !!voucherStreetNameParam &&
          !!voucherPostalCodeParam
            ? formatAddress({
                blockNumber: voucherBlockNumberParam,
                streetName: voucherStreetNameParam,
                postalCode: voucherPostalCodeParam,
                floorNumber: voucherFloorNumberParam,
                unitNumber: voucherUnitNumberParam,
              })
            : undefined
        }
        contactNumber={voucherContactNumberParam}
      />
      <Button
        alignSelf="flex-start"
        marginTop="40px"
        marginBottom="48px"
        colorScheme="primary"
        id="create-vouchers-confirm-next"
        onClick={onPrimaryClick}
      >
        {selectedVoucherType === VOUCHER_TYPES.DIGITAL &&
          'Yes, send vouchers via SMS'}
        {selectedVoucherType === VOUCHER_TYPES.PAPER && 'Print vouchers'}
      </Button>
    </Flex>
  )
}

export default ConfirmRecipientStep
