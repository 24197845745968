import React, { useCallback, useEffect, useState } from 'react'

import _ from 'lodash'
import { useCampaignContext } from 'routes/Campaigns/context/CampaignContext'
import CreateVoucherModal from './CreateVoucherModal'
import { useVouchersTabContext } from '../../VouchersTabContext'
import { CreateVoucherStoredFormContent } from './types'

const defaultValues: CreateVoucherStoredFormContent = {
  voucherNameParam: '',
  voucherContactNumberParam: '',
  voucherNricParam: '',
  voucherBlockNumberParam: '',
  voucherStreetNameParam: '',
  voucherPostalCodeParam: '',
  voucherFloorNumberParam: '',
  voucherUnitNumberParam: '',
}

// The prefix 65 is added to the contact number that was input by user before
// calling the API
const CONTACT_NUMBER_PREFIX = '65'
const CreateVoucherModalContainer = () => {
  const { campaignName, campaignDefaultVouchers } = useCampaignContext()

  const {
    currentCreateStep: currentStep,
    setCurrentCreateStep: setCurrentStep,
    selectedVoucherType,
    setSelectedVoucherType,
    createCampaignVoucher,
    getIsVoucherGroupsMatchingQueryExists,
    checkIfNricIsNotInWhitelistForCampaign,
    isCreateLoading,
    isCreateVoucherModalOpen,
    isCreateVoucherAddressUsePrefill,
    setIsCreateVoucherAddressUsePrefill,
    closeCreateVoucherModal,
    onCreateVoucherModalApplySearch,
  } = useVouchersTabContext()

  const [storedFormContent, setStoredFormContent] = useState<
    CreateVoucherStoredFormContent
  >(defaultValues)
  const onAddFormContent = useCallback(
    (data: Partial<CreateVoucherStoredFormContent>) =>
      setStoredFormContent((initalValue) => _.merge({}, initalValue, data)),
    []
  )
  const onPrimaryClick = useCallback(async () => {
    const {
      voucherNameParam,
      voucherContactNumberParam,
      voucherNricParam,
      voucherBlockNumberParam,
      voucherStreetNameParam,
      voucherPostalCodeParam,
      voucherFloorNumberParam,
      voucherUnitNumberParam,
    } = storedFormContent
    return createCampaignVoucher({
      voucherNameParam,
      // Add prefix if the contact number was actually filled up, otherwise just return itself
      voucherContactNumberParam: voucherContactNumberParam
        ? CONTACT_NUMBER_PREFIX + voucherContactNumberParam
        : voucherContactNumberParam,
      voucherNricParam,
      voucherBlockNumberParam,
      voucherStreetNameParam,
      voucherPostalCodeParam,
      voucherFloorNumberParam,
      voucherUnitNumberParam,
      voucherGroupDenominationsParam: campaignDefaultVouchers,
    })
  }, [createCampaignVoucher, campaignDefaultVouchers, storedFormContent])
  useEffect(() => {
    setStoredFormContent(defaultValues)
  }, [isCreateVoucherModalOpen])
  return (
    <CreateVoucherModal
      isOpen={isCreateVoucherModalOpen}
      getIsVoucherGroupsMatchingQueryExists={
        getIsVoucherGroupsMatchingQueryExists
      }
      checkIfNricIsNotInWhitelistForCampaign={
        checkIfNricIsNotInWhitelistForCampaign
      }
      campaignName={campaignName}
      defaultVouchers={campaignDefaultVouchers ?? []}
      selectedVoucherType={selectedVoucherType}
      setSelectedVoucherType={setSelectedVoucherType}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      isUsePrefill={isCreateVoucherAddressUsePrefill}
      setIsUsePrefill={setIsCreateVoucherAddressUsePrefill}
      onAddFormContent={onAddFormContent}
      storedFormContent={storedFormContent}
      onPrimaryClick={onPrimaryClick}
      isPrimaryLoading={isCreateLoading}
      onCloseClick={closeCreateVoucherModal}
      onApplySearch={onCreateVoucherModalApplySearch}
    />
  )
}

export default CreateVoucherModalContainer
