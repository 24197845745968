import React, { useContext } from 'react'
import { VouchersTabContext } from '../../VouchersTabContext'
import UpdateVoucherGroupModal from './UpdateVoucherGroupModal'

const UpdateVoucherGroupModalContainer: React.FC = () => {
  const {
    isUpdateVoucherGroupModalOpen,
    closeUpdateVoucherGroupModal,
    updateVoucherGroup,
    isUpdateVoucherGroupLoading,
    selectedGroupToUpdate,
  } = useContext(VouchersTabContext)
  return (
    <UpdateVoucherGroupModal
      isOpen={isUpdateVoucherGroupModalOpen}
      onCloseClick={closeUpdateVoucherGroupModal}
      onPrimaryClick={updateVoucherGroup}
      group={selectedGroupToUpdate}
      isLoading={isUpdateVoucherGroupLoading}
    />
  )
}

export default UpdateVoucherGroupModalContainer
