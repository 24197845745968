import { useCampaignTransactionReports } from 'hooks/CampaignReports'
import React, { useContext } from 'react'
import { useCampaignContext } from 'routes/Campaigns/context/CampaignContext'
import { VouchersTabContext } from '../../VouchersTabContext'
import DownloadTransactionsModal from './DownloadTransactionsModal'

type DownloadTransactionsReportContainerProps = Record<string, never>

const DownloadTransactionsReportContainer: React.FC<DownloadTransactionsReportContainerProps> = () => {
  const { campaignId } = useCampaignContext()
  const {
    isDownloadTransactionsModalOpen: isOpen,
    onCloseDownloadTransactionsModal: onClose,
  } = useContext(VouchersTabContext)

  const { campaignTransactionReports } = useCampaignTransactionReports(
    campaignId,
    null
  )

  return (
    <DownloadTransactionsModal
      isOpen={isOpen}
      onClose={onClose}
      reports={campaignTransactionReports}
    />
  )
}

export default DownloadTransactionsReportContainer
