import _ from 'lodash'
import { ValidationResult } from './types'

const NRIC_FIRST_CHARS = ['S', 'T', 'F', 'G']

export type SplittedNricForMasking = {
  toMask: string
  toShow: string
}

export class InvalidNricError extends Error {
  nric: string

  reason: string

  constructor({ nric, reason }: { nric: string; reason: string }) {
    super(`Invalid NRIC: ${nric}. Reason: ${reason}`)
    this.nric = nric
    this.reason = reason
  }
}

export function validateNric(nric: string): ValidationResult {
  if (!nric) {
    return { valid: false, reason: 'NRIC must not be empty' }
  }
  if (nric.length !== 9) {
    return { valid: false, reason: 'NRIC must be 9 characters long' }
  }
  if (nric.toUpperCase() !== nric) {
    return {
      valid: false,
      reason: 'NRIC must not contain lower-cased characters',
    }
  }

  const firstChar = nric.charAt(0)
  const lastChar = nric.charAt(nric.length - 1)

  if (!_.includes(NRIC_FIRST_CHARS, firstChar)) {
    return {
      valid: false,
      reason: 'First character of NRIC must be "S", "T", "F" or "G"',
    }
  }
  if (!/^\d+$/.test(nric.substring(1, 8))) {
    return {
      valid: false,
      reason: '2nd to 8th characters of NRIC must be numbers',
    }
  }

  const nricNumbers = nric.substring(1, 8).split('')

  const weightMultipliers = [2, 7, 6, 5, 4, 3, 2]

  const weights = _.zipWith(
    nricNumbers,
    weightMultipliers,
    (n, m) => parseInt(n, 10) * m
  )

  const totalWeight = _.reduce(weights, (sum, n) => sum + n, 0)

  const totalWeightWithOffset = _.includes(['T', 'G'], firstChar)
    ? totalWeight + 4
    : totalWeight

  const remainder = totalWeightWithOffset % 11

  const sOrT = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A']
  const fOrG = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K']

  const validCheckSumChar = _.includes(['S', 'T'], firstChar)
    ? sOrT[remainder]
    : fOrG[remainder]

  if (lastChar !== validCheckSumChar) {
    return {
      valid: false,
      reason: 'NRIC checksum invalid',
    }
  }

  return { valid: true }
}

export function splitNricForMasking(nric: string): SplittedNricForMasking {
  const validationResult = validateNric(nric)
  if (!validationResult.valid) {
    throw new InvalidNricError({ nric, reason: validationResult.reason })
  }

  return {
    toMask: nric.substring(0, 5),
    toShow: nric.substring(5),
  }
}
