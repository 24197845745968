import React, { useContext } from 'react'
import _ from 'lodash'

import { getAddressFromGroup } from 'helpers/utils'

import { VOUCHER_STATE } from 'constants/vouchers'
import VoucherTable from './VoucherTable'
import { VouchersTabContext } from '../../VouchersTabContext'
import GroupNricCellBody from './components/GroupNricCellBody'
import GroupContactCellBody from './components/GroupContactCellBody'

const getVoucherValueLeft = (group) => {
  return _(group.vouchers)
    .filter((voucher) => voucher.state === VOUCHER_STATE.UNUSED)
    .sumBy((voucher) => voucher.voucherValue)
}
// Table configuration to add fields and define the object property access
const TABLE_CONFIG = [
  {
    display: 'Name',
    key: 'name',
    headerFn: (group) => group.name || '-',
    // eslint-disable-next-line react/display-name
    bodyFn: (group) => <GroupNricCellBody group={group} />,
    width: '10rem',
  },
  {
    display: 'Contact details',
    key: 'contact',
    width: '25rem',
    headerFn: (group) => getAddressFromGroup(group) || '-',
    bodyFn: (group) => <GroupContactCellBody group={group} />,
  },
  // {
  //   display: 'Last activity',
  //   key: 'lastAction',
  //   width: '9.25rem',
  //   headerFn: () => '',
  //   bodyFn: () => '',
  // },
  {
    display: 'Value left',
    key: 'remaining value',
    width: '6rem',
    headerFn: (group) => `$${getVoucherValueLeft(group) || '0'} left`,
    bodyFn: () => '',
    hideOnHover: true,
  },
]

export default function VoucherTableContainer() {
  const {
    vouchers,
    isLoading,
    getNextPageOfVouchers,
    getPreviousPageOfVouchers,
    isLoadingNextPage,
    isLoadingPreviousPage,
    currentSearchQuery,
  } = useContext(VouchersTabContext)

  return (
    <VoucherTable
      vouchers={vouchers}
      isLoading={isLoading}
      config={TABLE_CONFIG}
      onClickNextPage={getNextPageOfVouchers}
      onClickPreviousPage={getPreviousPageOfVouchers}
      isLoadingNextPage={isLoadingNextPage}
      isLoadingPreviousPage={isLoadingPreviousPage}
      currentSearchQuery={currentSearchQuery}
    />
  )
}

VoucherTableContainer.propTypes = {}
